import React from "react";

import { CustomSpinner } from "components/common/custom_spinner";

export const MainLoading = ({ children, theme }) => {
    return <span className={`main-spinner ${theme}`}>
        <CustomSpinner />
        {children}
    </span>
}
