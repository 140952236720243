import React from "react";
import { Spin } from "antd";
import Icon from "@ant-design/icons";

import { ReactComponent as LogoSmallSVG } from "images/nextira_loading_ring.svg";

export const CustomSpinner = (props) => {
    return <Spin
        style={{ alignSelf: "center" }}
        indicator={<Icon
            component={LogoSmallSVG}
            style={{ fontSize: props.size || 48 }}
            spin
        />}
    />;
}